import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import Recaptcha from "react-recaptcha"
import { useMutation } from "@apollo/client"
import { JapaneseRegister } from "../crud/createRegister"

// Components
import { SectionTitle, Input } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
import { toast } from "react-toastify"
import { Seo } from "../components/seo"
import useSSR from "use-ssr"
const RegisterJapan = () => {
  const { isBrowser } = useSSR()
  const [register, setRegister] = useState({
    FullName: "",
    Email: "",
    ConfirmEmail: "",
    PhoneNumber: "0",
    Country: "",
    NativeLanguage: "-",
    WorkID: "0",
    WorkConditions: "",
    form_type: 1,
    PrevRegister: "",
    messageError: {},
    isVerified: false,
  })
  const [loading, setLoading] = useState(false)
  const { messageError } = register
  const emailRegex = RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
  const handleChange = e => {
    const { name, value } = e.target
    setRegister(prevState => ({
      ...prevState,
      [name]: value,
    }))

    let errors = register.messageError
    switch (name) {
      case "FullName":
        errors.FullName =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break

      case "Email":
        errors.Email =
          value === ""
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : "" || emailRegex.test(value)
            ? ""
            : "これは有効なメールではありません"
        break

      case "ConfirmEmail":
        errors.ConfirmEmail =
          value !== register.Email
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : ""
        break

      case "PrevRegister":
        errors.PrevRegister =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      default:
        break
    }
  }
  const [addJpRegister] = useMutation(JapaneseRegister, {
    onCompleted: () => {
      navigate("/thankyou")
    },
    onError: () => {
      setLoading(false)
      toast.error("サーバーエラー")
    },
  })

  const handleRegisterJapanese = () => {
    addJpRegister({
      variables: {
        input: {
          data: {
            FullName: register.FullName,
            Email: register.Email,
            PhoneNumber: register.PhoneNumber,
            Country: register.Country,
            NativeLanguage: register.NativeLanguage,
            WorkID: register.WorkID,
            WorkConditions: register.WorkConditions,
            form_type: register.form_type,
            PrevRegister: register.PrevRegister,
          },
        },
      },
    })
  }
  const [captchaVerify, setCaptchaVerify] = useState(false)
  const recaptchaVerify = response => {
    if (response) {
      setRegister(prevState => ({
        ...prevState,
        isVerified: true,
      }))
    } else {
      setCaptchaVerify(true)
      setTimeout(() => {
        setCaptchaVerify(false)
      }, 3000)
    }
  }
  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data).forEach(val => {
      val === "" && (valid = false)
    })
    return valid
  }
  const handleSubmit = event => {
    event.preventDefault()
    if (register.isVerified) {
      if (
        register.FullName !== "" &&
        register.Email !== "" &&
        register.PrevRegister !== "" &&
        register.ConfirmEmail !== ""
      ) {
        if (formValidate(register.messageError, register)) {
          setLoading(true)
          handleRegisterJapanese()
        } else {
          toast.error("フォームが正しくありません")
        }
      } else {
        toast.error("入力内容を再度ご確認ください。")
      }
    } else {
      recaptchaVerify()
    }
  }
  return (
    <>
      <section className="container contact-us">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="contact-us__responsible-for-company">
              <SectionTitle>日本在住者お申し込みフォーム</SectionTitle>
              <hr className="u-line-bottom" />
              <p>
                弊社サポートの詳細は『
                <Link to="/process">お仕事紹介の流れ</Link>』をご覧ください。
              </p>
              <form
                className="contact-us__form-contact"
                onSubmit={handleSubmit}
              >
                <div className="form-responsive">
                  <Input>
                    <label htmlFor="氏名（フルネーム）">
                      <p className="form-label">氏名（フルネーム） </p>
                      <span>必須</span>
                    </label>
                    <input
                      type="text"
                      id="氏名（フルネーム）"
                      name="FullName"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                    {messageError.FullName && (
                      <span className="field-validation">
                        {messageError.FullName}
                      </span>
                    )}
                  </Input>

                  <Input>
                    <label htmlFor="メールアドレス">
                      <p className="form-label">メールアドレス </p>
                      <span>必須</span>
                    </label>
                    <input
                      type="text"
                      id="メールアドレス"
                      name="Email"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                    {messageError.Email && (
                      <span className="field-validation">
                        {messageError.Email}
                      </span>
                    )}
                  </Input>
                  <Input>
                    <label htmlFor="メールアドレスの再入力">
                      <p className="form-label"> メールアドレスの再入力 </p>
                      <span>必須</span>
                    </label>
                    <input
                      type="text"
                      id="メールアドレスの再入力"
                      name="ConfirmEmail"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                    {messageError.ConfirmEmail && (
                      <span className="field-validation">
                        {messageError.ConfirmEmail}
                      </span>
                    )}
                  </Input>
                  <Input>
                    <label htmlFor="電話番号">
                      <p className="form-label">電話番号</p>
                    </label>
                    <input
                      type="text"
                      id="電話番号"
                      name="PhoneNumber"
                      onChange={handleChange}
                    />
                  </Input>
                  <Input>
                    <label htmlFor="国籍">
                      <p className="form-label">国籍</p>
                    </label>
                    <input
                      type="text"
                      id="国籍"
                      name="Country"
                      onChange={handleChange}
                    />
                  </Input>
                  <Input>
                    <label htmlFor="母国語">
                      <p className="form-label">母国語</p>
                    </label>
                    <select
                      id="母国語"
                      name="NativeLanguage"
                      onChange={handleChange}
                      onBlur={handleChange}
                    >
                      <option value="-">-</option>
                      <option value="日本語">日本語</option>
                      <option value="スペイン語">スペイン語</option>
                      <option value="英語">英語</option>
                      <option value="その他言語">その他言語</option>
                    </select>
                  </Input>
                  <Input>
                    <label htmlFor="希望する求人番号">
                      <p className="form-label">希望する求人番号</p>
                    </label>
                    <input
                      type="text"
                      id="希望する求人番号"
                      placeholder="例：1000、1001"
                      name="WorkID"
                      onChange={handleChange}
                    />
                  </Input>
                  <Input>
                    <label htmlFor="area">
                      <p className="form-label">
                        希望条件（ 職種、勤務地等）、その他
                      </p>
                    </label>
                    <textarea
                      id="area"
                      name="WorkConditions"
                      onChange={handleChange}
                    />
                  </Input>
                  <Input>
                    <label htmlFor="PrevRegister">
                      <p className="form-label">以前にも登録したことがある</p>
                      <span>必須</span>
                    </label>
                    <div className="input-checkbox">
                      <div className="input-checkbox__check-box">
                        <input
                          type="radio"
                          id="yes"
                          value="YES"
                          name="PrevRegister"
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                        <label htmlFor="yes">
                          <p className="form-label">はい</p>
                        </label>
                      </div>
                      <div className="input-checkbox__check-box">
                        <input
                          type="radio"
                          id="no"
                          value="NO"
                          name="PrevRegister"
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                        <label htmlFor="no">
                          <p className="form-label">いいえ</p>
                        </label>
                      </div>
                      {messageError.PrevRegister && (
                        <span className="field-validation">
                          {messageError.PrevRegister}
                        </span>
                      )}
                    </div>
                  </Input>
                  <div className="captcha-style">
                    {isBrowser && (
                      <Recaptcha
                        render="explicit"
                        sitekey="6Lf4VfAbAAAAAO1Fu6ZqpDdvbs929UMuN04vwA3A"
                        name="isVerify"
                        verifyCallback={recaptchaVerify}
                      />
                    )}

                    {captchaVerify ? (
                      <span className="field-validation-captcha">
                        Please verify that you are not a robot
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </form>
            </div>
            <Contact lang={"ja"} />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default RegisterJapan

export const Head = () => {
  return (
    <Seo
      title="日本在住者お申し込みフォーム"
      description="メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントサービス。メキシコ国内の転職だけでなく、日本からの転職もサポート。"
      url={`https://www.919mexico.com/register_japan`}
    />
  )
}
